package app

import dev.fritz2.core.RenderContext
import dev.fritz2.core.disabled
import dev.fritz2.core.src
import dev.fritz2.routing.Router
import kotlinx.coroutines.flow.map

object ArticlePage {
    private val gods: List<God> = listOf(
        hera,
        zeus,
        poseidon,
        demeter,
        artemis,
        apollo,
        ares,
        aphrodite,
        athena,
        dionysus,
        hephaestus,
        hermes,
        hestia,
        hades
    )

    fun render(context: RenderContext, router: Router<String>) {
        context.section("w-10/12 mx-auto") {
            button("text-pink-600 hover:text-teal-700 font-bold") {
                +"HOME"
                clicks.map { "" } handledBy router.update
            }
        }

        context.section("prose w-10/12 mx-auto") {
            h1("dark:prose-invert") { +"Olympus Reimagined" }
            h2("dark:prose-invert") { +"Designing an AI System with Subsystem Agents Inspired by the Greek Pantheon" }

            img {
                src("./images/mount-olympus-2.png")
            }

            p("hyphens-auto break-keep text-zinc-900") {
                +"""
                    In the ever-evolving world of artificial intelligence, researchers and developers 
                    continuously strive to create AI systems that can function with the complexity and 
                    adaptability of the human brain. The quest to emulate the brain's intricate processes 
                    has led to the development of an innovative AI system architecture that employs a 
                    network of subsystem AI agents, each representing a specific role, working in harmony 
                    to regulate and manage the greater AI system. This novel approach takes its 
                    inspiration from an ancient source – the Greek Pantheon, where each of the 14 Greek 
                    Gods symbolizes a distinct aspect of the human brain and its cognitive functions.
                """.trimIndent()
            }

            p("hyphens-auto break-keep text-zinc-900") {
                +"""
                    This article delves into the fascinating concept of designing AI subsystems based on 
                    the characteristics and attributes of the Greek Gods, shedding light on how these 
                    divine archetypes can serve as a foundation for creating highly specialized AI modules. 
                    By exploring the mythological roots of these deities and their connection to the human 
                    brain, we will unravel the potential of this AI architecture to revolutionize our 
                    understanding of artificial intelligence and its applications.
                """.trimIndent()
            }

            p {
                +"Welcome! This article is under construction!"
            }
        }

        context.newest()
    }

    private fun RenderContext.newest() = this.main("mx-auto border rounded-md bg-white p-10 w-3/4 mt-10") {
        div("grid grid-cols-1 md:grid-cols-2 gap-4") {
            gods.forEach(this::card)
        }
    }
}

fun RenderContext.card(god: God) = section {
    h1("text-[48pt] jetlab-variable") { +god.name }
    img("w-full h-auto") {
        src("./images/${god.portrait}")
    }
    p("my-4") {
        +(god.synopsis ?: "")
    }
    button("border rounded-md border-pink-500 text-pink-500 px-4 py-2") {
        +"Coming Soon"
        disabled(true, "disabled")
    }
}