package app

import dev.fritz2.core.*
import dev.fritz2.routing.Router
import kotlinx.coroutines.flow.map

object LandingPage {

    fun render(context: RenderContext, router: Router<String>) {
        context.main("h-full") {
            section("relative") {
                div("absolute top-24 z-50 shadow-inner w-full md:w-11/12 left-3 md:left-5 flex justify-between md:px-10 px-4") {
                    h1("font-mono text-3xl md:text-4xl font-bold shadow-2xl text-white") { +"Violabs Software" }
                    a("bg-pink-500 md:px-0 px-2 w-12 md:h-12 h-10 flex justify-center items-center rounded font-white hover:bg-pink-600 active:bg-pink-700") {
                        target("_blank")
                        href("https://github.com/violabs")
                        svg("bi bi-github") {
                            xmlns("http://www.w3.org/2000/svg")
                            attr("width", "16")
                            attr("height", "16")
                            viewBox("0 0 16 16")
                            fill("white")
                            path {
                                d(
                                    """
                                        M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 
                                        0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 
                                        1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 
                                        0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 
                                        3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z
                                    """.trimIndent()
                                )
                            }
                        }
//                        i("bi bi-github") {  }
                    }
                }
                div("flex md:flex-row flex-col md:space-x-2 opacity-80 animate-pulse ") {
                    var current = SHADOWS

                    listOf(
                        listOf(1, 3, 5, 6),
                        listOf(0, 4),
                        listOf(0, 2, 3, 5, 6),
                        listOf(0, 1, 3, 5, 7),
                        listOf(1, 4, 5),
                        listOf(1, 5),
                        listOf(3, 4, 7),
                        listOf(1, 5, 6),
                        listOf(4),
                        listOf(2, 6),
                        listOf(1, 4, 7),
                        listOf(1, 2, 4, 5, 6),
                        listOf(0, 1, 2, 4, 5, 7),
                        listOf(0, 1, 4, 5, 6)
                    ).forEach { onIndexes ->
                        div("flex md:flex-col md:space-x-0 space-x-2") {
                            current.forEachIndexed { i, shadow ->
                                val added = if (i in onIndexes) "bg-teal-500" else "border-teal-500 border"
                                div("$shadow md:w-12 md:h-12 w-8 h-8 mb-2 $added") {}
                            }
                        }
                        current = current.rotate()
                    }
                }
            }
            section {
                button("text-transparent hover:text-pink-500 font-bold") {
                    +"ARTICLES"
                    clicks.map { "articles" } handledBy router.update
                }
            }
        }
    }
}