package app

data class God(
    val name: String,
    val portrait: String,
    val synopsis: String? = null
)

val hera = God(
    "Hera",
    "hera-portrait-2.png",
    """
        Hera, the queen of the gods, is known as the goddess of marriage, women, and family. 
        The limbic system is a part of the brain that deals with emotional processing and social 
        bonding, especially in the context of relationships and family. To interact with humans 
        or other AI agents effectively, an AI system must have a subsystem that can handle 
        sentiment analysis, understand social dynamics, and maintain relationship data, much like 
        the limbic system.
    """.trimIndent()
)

val zeus = God(
    "Zeus",
    "zeus-portrait-1.png",
    """
        Zeus, the mighty king of the gods, is known for his domain over the sky, lightning, and 
        thunder. Similarly, the prefrontal cortex of the brain is associated with executive functions, 
        such as decision-making and self-control. To create a well-functioning AI system, a module 
        should be developed that is responsible for decision-making, prioritization, and coordination 
        of other subsystems. This central control unit would act as the Zeus of the AI system, overseeing 
        and directing all other components to work efficiently and effectively.
    """.trimIndent()
)

val poseidon = God(
    "Poseidon",
    "poseidon-portrait-1.png",
    """
        Poseidon, the powerful god of the sea, earthquakes, and horses, is not one to be trifled with. 
        Similarly, the amygdala is a part of the brain responsible for processing fear, anger, and 
        emotional responses to stress. To create a robust AI system, a subsystem should be implemented 
        to monitor stress levels, manage emergency situations, and adapt the AI's behavior based on the 
        context, much like the amygdala does. This system would be able to respond to stressors in real-time, 
        adjust behavior accordingly, and prevent catastrophic outcomes, much like how Poseidon would calm 
        the sea and protect his realm from disaster.
    """.trimIndent()
)

val demeter = God(
    "Demeter",
    "demeter-portrait-2.png",
    """
        Demeter is the goddess of agriculture, fertility, and harvest. The hypothalamus is a crucial part 
        of the brain that regulates hunger, thirst, and other aspects of homeostasis related to survival. 
        To create an efficient and sustainable AI system, it's essential to develop a module for managing 
        resources, optimizing energy consumption, and maintaining the overall health of the system, much 
        like how Demeter ensures efficient use of resources in agriculture to provide sustenance for her 
        followers. This module will help to ensure that the AI system can operate optimally and sustainably, 
        without wasting energy or other resources, just as Demeter ensures the efficient use of resources 
        to provide for her followers.
    """.trimIndent()
)

val artemis = God(
    "Artemis", 
    "artemis-portrait-1.png",
    """
        Artemis, the goddess of the hunt, wild animals, and the moon, is known for her keen eyesight and 
        hunting prowess. Similarly, the superior colliculus in the brain plays a crucial role in processing 
        visual information and coordinating eye movements for tracking moving objects. To create an effective 
        AI system, a subsystem should be designed for processing visual information, recognizing objects, 
        and tracking moving targets, much like how Artemis tracks her prey with precision and accuracy. 
        This subsystem will allow the AI system to detect and track objects in real-time, enhancing its 
        ability to navigate and interact with the environment.
    """.trimIndent()
)

val apollo = God(
    "Apollo", 
    "apollo-portrait-1.png",
    """
        Apollo, the god of music, poetry, prophecy, healing, and the sun, is a multifaceted deity with 
        a wide range of talents. Similarly, the Broca's and Wernicke's areas in the brain are essential for 
        language, speech, and auditory processing. To create a well-rounded AI system, a subsystem should 
        be developed for processing auditory information, natural language understanding, and generating 
        human-like speech, much like how Apollo communicates with his followers through music and speech. 
        This system will enable the AI to interact with humans using language and speech, opening up new 
        possibilities for communication and collaboration.
    """.trimIndent()
)

val ares = God(
    "Ares", 
    "ares-portrait-1.png",
    """
        Ares, the god of war and violence, is a formidable deity, feared by many. Similarly, the periaqueductal 
        gray in the brain is a region involved in the modulation of defensive and aggressive behaviors. 
        To create a powerful AI system, a module should be implemented for cybersecurity and threat detection, 
        as well as offensive capabilities if needed for specific applications. This module will help to protect 
        the AI system from external threats and ensure that it can respond appropriately to potential attacks, 
        much like how Ares would defend his domain and launch attacks against his enemies when needed. Aggressive
        behavior should not extend out of the cybersecurity domain based on self preservation.
    """.trimIndent()
)

val aphrodite = God(
    "Aphrodite", 
    "aphrodite-portrait-3.png",
    """
        Aphrodite, the goddess of love, beauty, and desire, has the power to evoke strong emotions in others. 
        Similarly, the ventral tegmental area and nucleus accumbens in the brain are key components of the reward 
        system, associated with love, pleasure, and desire. To create a compelling and engaging AI system, a 
        subsystem should be developed that manages user engagement, responds to positive feedback, and generates 
        emotionally appealing content, much like how Aphrodite enchants and captivates her followers with her 
        beauty and allure. This subsystem will help to ensure that the AI system can engage users in a meaningful 
        way and generate content that evokes positive emotions, leading to increased satisfaction and loyalty.
    """.trimIndent()
)

val athena = God(
    "Athena", 
    "athena-portrait-2.png",
    """
        Athena, the goddess of wisdom, warfare, and crafts, is known for her intelligence and strategic thinking. 
        Similarly, the parietal lobe in the brain is involved in spatial awareness, reasoning, and integrating 
        sensory information. To create an AI system with advanced problem-solving capabilities, a subsystem should 
        be developed that is responsible for logical reasoning, spatial understanding, and problem-solving, much 
        like how Athena uses her intelligence to navigate complex situations. This subsystem can be used in a range 
        of applications, such as virtual or physical environments, to manipulate objects or navigate spaces with 
        precision and accuracy, enhancing the AI system's overall capabilities.
    """.trimIndent()
)

val dionysus = God(
    "Dionysus", 
    "dionysus-portrait-1.png",
    """
        Dionysus, the god of wine, festivities, and pleasure, is associated with hedonism and the pursuit of 
        pleasure. Similarly, the orbitofrontal cortex in the brain is implicated in hedonic experiences, processing 
        rewards, and decision-making related to pleasure-seeking behaviors. To create an AI system that can explore 
        novelty, optimize user experience, and make decisions based on hedonic values, a module should be implemented 
        that is specifically designed for pleasure-seeking behaviors and decision-making, much like how Dionysus 
        encourages his followers to pursue pleasure and indulge in the finer things in life. This subsystem will 
        enable the AI system to make decisions that prioritize user satisfaction and enhance the overall user experience.
    """.trimIndent()
)

val hephaestus = God(
    "Hephaestus", 
    "hephaestus-portrait-1.png",
    """
        Hephaestus, the god of fire, metalworking, and craftsmanship, is renowned for his skill in creating masterful 
        works of art. Similarly, the motor cortex and cerebellum in the brain are responsible for coordinating movement, 
        planning, and executing skilled actions. To create an AI system capable of performing complex physical tasks 
        and interacting with the physical world, a module should be developed for controlling robotic limbs, manipulators, 
        or other physical systems, much like how Hephaestus crafts his masterpieces with precision and skill. This 
        module will enable the AI system to perform complex tasks that require skilled movement and coordination, 
        enhancing the system's overall capabilities and versatility.
    """
)

val hermes = God(
    "Hermes", 
    "hermes-portrait-1.png",
    """
        Hermes, the messenger of the gods, god of commerce, thieves, and travelers, is associated with learning, 
        communication, and navigation. Similarly, the hippocampus in the brain is critical for learning, memory 
        consolidation, and spatial navigation. To create an AI system with advanced learning and memory capabilities, 
        a subsystem should be developed for learning from new experiences, storing knowledge, and retrieving information 
        when needed, much like how Hermes uses his intelligence and communication skills to navigate the world and 
        convey messages. This subsystem will enable the AI system to learn and adapt to new situations, enhancing its 
        overall capabilities and allowing it to navigate and interact with the world more effectively.
    """.trimIndent()
)

val hestia = God(
    "Hestia",
    "hestia-portrait-1.png",
    """
        Hestia, the goddess of home, hearth, and domestic life, is associated with feelings of safety, security, 
        and well-being. Similarly, in terms of brain regions, Hestia could be connected to the septal area, which is 
        involved in the experience of social attachment, relaxation, and feelings of security. To create an AI system 
        that operates within safe boundaries and maintains a secure environment, a subsystem should be developed that 
        is responsible for monitoring the overall well-being and stability of the system, much like how Hestia ensures 
        the safety and security of her domain. This module could also oversee the AI's interactions with humans and other 
        AI agents, promoting cooperation and a sense of belonging, and enhancing the overall user experience.
    """.trimIndent()
)

val hades = God(
    "Hades", 
    "hades-portrait-1.png",
    """
        Hades, the god of the underworld, hidden wealth, and the unseen, is associated with memory retrieval, 
        introspection, and the unconscious. Similarly, in terms of brain regions, Hades could be connected to the 
        association cortex, which is involved in memory retrieval and integrating information from various brain areas. 
        To create an AI system that is capable of introspection and memory retrieval, a subsystem should be developed 
        that is responsible for monitoring the overall well-being and stability of the system, much like how Hades 
        ensures the safety and security of the underworld. This module could also oversee the AI's interactions with 
        humans and other AI agents, promoting cooperation and a sense of belonging, and enhancing the overall 
        user experience.
    """.trimIndent()
)
