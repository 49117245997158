package app

import dev.fritz2.core.*
import dev.fritz2.routing.Router
import dev.fritz2.routing.routerOf

val SHADOWS = mutableListOf(
    "shadow-2xl",
    "shadow-xl",
    "shadow-lg",
    "shadow-md",
    "shadow",
    "shadow-sm",
    "shadow-none",
    "shadow-inner"
)

fun MutableList<String>.rotate(): MutableList<String> = apply { this.add(removeFirst()) }

fun main() {
    val router: Router<String> = routerOf("")

    render {
        // card
        div("max-w-full mx-auto flex flex-col items-center md:block sm:px-6 lg:px-8 py-12 h-full min-h-full") {
            router.data.render {
                when(it) {
                    "articles" -> ArticlePage.render(this, router)
                    else -> LandingPage.render(this, router)
                }
            }
        }
    }
}